import api from '../index'

const user = {
    // 获取绑定用户信息
    getUserInfo(data) {
        return api({
            url: 'v2/ofs/user-info',
            method: 'post',
            data: data
        })
    },
    // 绑定用户信息
    bindUserInfo(data) {
        return api({
            url: 'v2/ofs/bind-info',
            method: 'post',
            data: data
        })
    },
    // 解绑信息
    unbindUserInfo(data) {
        return api({
            url: 'v2/ofs/unbinding',
            method: 'post',
            data: data
        })
    },

}
export default user
