<!-- 主页 -->
<template>
  <div class="page-main">
    <div v-if="!isLogin" class="login-loading">
      <van-loading type="spinner" vertical>
        加载中...
      </van-loading>
    </div>
    <div v-else>
      <HomePage />
    </div>
  </div>
</template>

<script>
import HomePage from "@/views/homepage/index.vue"
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
// import { getSession, setSession } from "../../utils/sessionUtils"
import { getStorage, setStorage } from "../../utils/localStorageUtils"
import CommonData from "../../utils/commonData"
import login from "../../api/ofs/login"

export default {
  name: "home",
  components: {
    HomePage,
  },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    const wxLogin = async () => {
      await login.login({}).then(res=> {
        console.log(res)
        setStorage(CommonData.KeyStorages.KEY_USER_TOKEN, res.data.token)
        setStorage(CommonData.KeyStorages.KEY_IS_LOGIN, true)
        data.isLogin = true
      }).catch(err=>{
        console.log(err)
      })
    }

    onMounted(() => {
      console.log('home=====>')
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      if (options!=null) {
        // if (options.open_id != null) {
        //   var open_id = options.open_id
        //   console.log('set session=====>', open_id)
        //   setSession(CommonData.KeyStorages.KEY_USER_OPEN_ID, open_id)
        // }
        if (options.organ_id != null) {
          var organ_id = options.organ_id
          setStorage(CommonData.KeyStorages.KEY_ORGAN_ID, organ_id)
        }
      }
      console.log(getStorage(CommonData.KeyStorages.KEY_USER_OPEN_ID))
      if (getStorage(CommonData.KeyStorages.KEY_USER_OPEN_ID)) {
        wxLogin()
      } else {
        data.isLogin = true
      }
    });
    const data = reactive({
      isLogin: false
    });

    return {
      ...toRefs(data)
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  .login-loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
