<!-- 首页 -->
<template>
  <div class="page-main">
    <!-- <header class="page-header">
      <div class="header-content">首页</div>
    </header> -->
    <section class="page-info">
      <div>
        <van-image
          width="100%"
          fit="fill"
          style="z-index:0"
          :src="require('@/assets/image/bk.png')"
        />
      </div>
      <div class="page-info-header">
        <div class="content-body">
          <div v-if="is_band" class="info-card">
            <div class="card-content">
              <div class="box" @click="handleUnbinding">
                <div class="ribbon-2">解绑信息</div>
              </div>
              <div class="person-info">
                <van-image
                  round
                  width="55px"
                  height="55px"
                  :src="require('@/assets/image/avator.png')"
                />
                <div style="margin-left: 10px">{{userInfo.patient_name}}</div>
              </div>
              <div class="person-tag">
                <div class="tag-item" v-for="(item, index) in userInfo.diseases_cate" :key="index">
                  <van-tag type="primary">{{item.name}}</van-tag>
                </div>
              </div>
              <!-- <van-divider textColor="#FFFFFF" /> -->
              <!-- <div class="detail-opt">
                <van-button round plain hairline size="small" type="primary" @click="handleGoBill">交易明细</van-button>
              </div> -->
            </div>
          </div>
          <div v-else class="info-card">
            <div class="card-content">
              <div class="bind-attention">
                <van-icon size="16px" name="question" style="margin-right:5px" />
                绑定信息，即可轻松在线订餐
              </div>
              <div class="detail-opt">
                <van-button round plain hairline size="small" type="primary" @click="handClickBind">立即绑定</van-button>
              </div>
            </div>
          </div>
          <div class="meal-order">
            <!-- <div class="order-info" @click="handleGoMealOrder(1)">
              <van-icon size="40px" color="#9F88FF" name="shopping-cart-o" />
              <div>
                今日订餐
              </div>
            </div> -->
            <div class="order-info" @click="handleGoMealOrder(2)">
              <van-icon size="40px" color="#9F88FF" name="birthday-cake-o" />
              <div>
                明日订餐
              </div>
            </div>
            <!-- <div class="order-info" @click="handlePrefabOrder">
              <van-icon size="40px" color="#9F88FF" name="comment-o" />
              <div>
                预制菜
              </div>
            </div> -->
            <div class="order-info" @click="handleGoBill">
              <van-icon size="40px" color="#9F88FF" name="balance-list-o" />
              <div>
                我的订单
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 资讯 -->
      <div style="background: #FFFFFF;margin-top:20px;border-radius: 20px 20px 0 0;">
        <div class="content-body">
          <div class="information-header">
            营养必看
          </div>
          <div class="information-body">
            <van-tabs
              v-model:active="active"
              bind:change="onChange"
              sticky
              swipeable
              animated
              title-active-color="#9F88FF"
              color="#9F88FF"
              line-width="20px"
              :ellipsis=false
            >
              <van-tab v-for="(item, index) in preachTabList" :key="index" :title="item.name">
                <div v-if="active == index">
                  <Information :cate_id="item.id" />
                </div>
              </van-tab>
            </van-tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import Information from "./component/information.vue"
import CommonData from "../../utils/commonData"
import user from "../../api/ofs/userInfo"
import preach from "../../api/ofs/preach"

import { Toast,Dialog } from 'vant'

export default {
  name: "homepage",
  components: {
    Information,
  },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    const data = reactive({
      userInfo: {},
      active: 0,
      preachTabList: [],
      is_band: false
    });

    onMounted(() => {
      // 获取患者绑定信息
      if (localStorage.getItem(CommonData.KeyStorages.KEY_USER_OPEN_ID)) {
        console.log("getUserInfo =====================> ")
        getUserInfo()
      }
      getTablist()
    });
    const getUserInfo = () => {
      user.getUserInfo({}).then(res => {
        if (res.data != null) {
          data.userInfo = res.data
          data.is_band = true
        }
      })
    }
    const getTablist = () => {
      preach.getPreachCate({}).then(res => {
        if (res.data != null) {
          data.preachTabList = res.data
        }
      })
    }

    const handClickBind = () => {
      $router.push({
        name: 'bind'
      })
    }
    const handleGoOrder = type => {
      if (!data.is_band) {
        handClickBind()
        return
      }
      const params = {
        type: type,
        shop_type: 1
      }
      $router.push({
        query: params,
        name: 'makeOrder'
      })
    }
    const handleGoMealOrder = type => {
      if (!data.is_band) {
        handClickBind()
        return
      }
      const params = {
        type: type,
        shop_type: 1
      }
      $router.push({
        query: params,
        name: 'makeMealOrder'
      })
    }
    const handleGoBill = () => {
      if (!data.is_band) {
        handClickBind()
        return
      }
      $router.push({
        name: 'bill'
      })
    }
    const handlePrefabOrder = () => {
      if (!data.is_band) {
        handClickBind()
        return
      }
      const params = {
        shop_type: 2
      }
      $router.push({
        query: params,
        name: 'makePrefabOrder'
      })
    }
    const handleUnbinding = () => {
      Dialog({
        title: '解绑信息',
        message:
          '确定取消账号绑定信息！',
        showCancelButton: true,
      })
      .then(() => {
        user.unbindUserInfo({}).then(res=>{
          Toast('解绑成功');
          data.is_band = false
        }).catch(err=>{
          Toast(err.message);
        })

      })
      .catch(() => {
        // on cancel
      });
    }

    return {
      ...toRefs(data),
      handClickBind,
      handleGoOrder,
      handleGoMealOrder,
      handleGoBill,
      handlePrefabOrder,
      handleUnbinding
    };
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  background: #F2F2F2;
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background: #9F88FF;
    color: #FFFFFF;
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .page-info {
    height: 100%;
    .page-info-header {
      background: #FFFFFF;
      border-radius: 20px;
      margin-top: -20px;
      z-index: 99;
    }
    .content-body {
      padding: 20px;
    }
    .info-card {
      margin: 20px 0;
      border-radius: 20px;
      background: linear-gradient(to right, #9F88FF, #E8CCFF);
      min-height: 50px;
      .detail-opt {
        text-align: center;
      }
      .card-content {
        padding: 20px;
        color: #FFFFFF;
        position:relative;
        .ribbon-2 {
          --f: 10px; /* control the folded part*/
          --r: 15px; /* control the ribbon shape */
          --t: 20px; /* the top offset */
          position: absolute;
          inset: var(--t) calc(-1*var(--f)) auto auto;
          padding: 0 10px var(--f) calc(10px + var(--r));
          clip-path:
            polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
              calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
              var(--r) calc(50% - var(--f)/2));
          background: #BD1550;
          box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
          font-size: 14px;
        }
        .person-info {
          display: flex;
          flex-wrap: wrap;
          font-size: 20px;
          align-items: center;
          justify-content: flex-start;
          .relese-bind {
            display: inline-block;
            position: fixed;
            top: 0;
            right: 0;
          }
        }
        .person-tag {
          display: flex;
          flex-wrap: wrap;
          margin: 10px 0px;
          .tag-item {
            margin-left: 5px;
          }
        }
      }
      .bind-attention {
        color: #FFFFFF;
        padding: 0 20px 20px 20px;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }
    .meal-order {
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-between;
      .order-info {
        font-size: 14px;
        text-align: center;
        line-height: 20px;
        margin-right: 15px;
      }
    }
    .information-header {
      font-size: 16px;
      color: #9F88FF;
    }
  }
  .intro-body {
    max-height: 50%;
    margin: 20px;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    .intro-content {
      margin-bottom: 20px;
    }
  }
}

</style>
