<!-- 资讯信息 -->
<template>
  <div class="informatin-page-main">
    <van-list
      v-model:loading="loading"
      :finished="tableList.length==total"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell v-for="(item, index) in tableList" :key="index">
        <div class="info-item"  @click="handleGoNutritionInfo(item.preach_id)">
          <div>
            <van-image
              width="100px"
              height="50px"
              fit="cover"
              :src="require('@/assets/image/zixun.png')"
            />
          </div>
          <div class="item-desc">
            <div class="item-title">{{item.title}}</div>
            <div class="item-content">{{item.upd_dt}}</div>
          </div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

import preach from "../../../api/ofs/preach"

export default {
  name: "nutritionInformation",
  components: {},
  props: {
    cate_id: {
      type: Number,
      default: 0
    },
  },
  setup(props, setupContext) {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    const data = reactive({
      total: 0,
      tableList: [],
      loading: false,
      page_index: 1,
      page_size: 10
    });

    onMounted(() => {
      //获取宣教内容
      getTableList()
    });

    const onLoad = () => {
      console.log("加载更多！")
      data.page_index += 1
      getTableList()
    }

    const getTableList = () => {
      console.log(props.cate_id)
      var post_data  = {
        cate_id: props.cate_id,
        page_index: data.page_index,
        page_size: data.page_size
      }
      preach.getPreachList(post_data).then(res=> {
        data.total = res.data.total
        if (data.page_index==1) {
          data.tableList = res.data.data
        } else {
          data.tableList = [...data.tableList, ...res.data.data]
        }
        data.loading = false
      })
    }

    const handleGoNutritionInfo = preach_id => {
      const params = {
        preach_id: preach_id
      }
      $router.push({
        query: params,
        name: 'nutritionInformation'
      })
    }

    return {
      ...toRefs(data),
      handleGoNutritionInfo,
      onLoad
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.informatin-page-main {
  width: 100%;
  margin: 10px 0;
  .info-item {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    border-radius: 20px;
    // margin: 15px 0;
    .item-desc {
      margin-left: 10px;
      max-width: calc(100% - 110px);
    }
    .item-title {
      font-size: 16px;
      line-height: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .item-content {
      font-size: 14px;
      line-height: 20px;
      color: #AAAAAA;
    }
  }
  .shopping-card-none {
    height: 100%;
    font-size: 18px;
    color: #AAAAAA;
  }
}

</style>
